import React from 'react';
import cn from 'classnames';
import Arrow from 'components/Arrow';
import Button from "../../../components/Button";
import css from './styles.module.scss';
import mainBg from '../../../assets/img/main_bg.png';
import pictureImg from '../../../assets/img/picture/picture1.png';
import avatar from '../../../assets/img/avatars/avatar.png';

const Slider: React.FC = () => {
    return (
        <div className={css.section} style={{ backgroundImage: `url(${mainBg}` }}>
        <div className={cn('container', css.body)}>
          <div className={css.information}>
            <div className={css.title}>
              Discover, collect, and sell extraordinary NFTs
            </div>
            <div className={css.subTitle}>
              on the world`s first & largest NFT marketplace
            </div>
            <div className={css.buttons}>
              <Button text="Explore & Buy" color="primary" />
              <Button text="Create & Sell" color="secondary" />
            </div>
          </div>
          <div className={css.slider}>
            <Arrow left/>
            <div className={css.pictures}>
              <img className={css.img} src={pictureImg} alt="autor" />
            </div>
            <div className={css.info}>
              <div className={css.autor_info}>
                <img className={css.avatar} src={avatar} alt="avatar" />
              </div>
              <div className={css.picture_info}>
                <div className={css.picture_name}>Red Tornado</div>
                <div className={css.autor_name}>Ed Palmer</div>
              </div>
            </div>
            <Arrow right/>
          </div>
        </div>
      </div>
    );
}

export default Slider;