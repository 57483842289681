/* eslint-disable no-use-before-define */
/* eslint-disable @typescript-eslint/no-explicit-any */

import React from 'react';
import cn from 'classnames';
import './styles.scss';
import Arrow from 'components/Arrow';

interface Prop {
  element?: any;
  classname?: any;
}

const ScrollToTopButton: React.FC<Prop> = ({ element, classname }) => {
  const [visible, setVisible] = React.useState(false);

  React.useEffect(() => {
    if (element) {
      element.current.addEventListener('scroll', onScroll);
      return () => element.current.removeEventListener('scroll', onScroll);
    }
    document.addEventListener('scroll', onScroll);
    return () => document.removeEventListener('scroll', onScroll);
  }, [document]);

  const onScroll = (event: any) => {
    if (element !== undefined) {
      if (event.target.scrollTop < 58) {
        return setVisible(false);
      }
      return setVisible(true);
    }
    if (event.target.scrollingElement.scrollTop < 58) {
      return setVisible(false);
    }
    return setVisible(true);
  };

  return (
    <div
      className={cn('scroll-to-top-button', classname, { isVisible: visible })}
      onClick={() => {
        if (element !== undefined) {
          element.current.children[0].children[0].scrollIntoView({
            behavior: 'smooth',
            block: 'end',
          });
        } else {
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
        }
      }}
    >
      <div>To top</div>
      <Arrow top className="scroll-to-top-arrow" />
    </div>
  );
};
export default ScrollToTopButton;
