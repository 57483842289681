import React from 'react';
import cn from 'classnames';
import css from './styles.module.scss';
import arrowSvg from './icons/arrow.svg';

interface Prop {
  left?: boolean;
  right?: boolean;
  top?: boolean;
  bottom?: boolean;
  className?: string;
}

const Arrow: React.FC<Prop> = ({ left, right, top, bottom, className }) => {
  return (
    <div
      className={cn(css.arrow, className, {
        _isLeft: left,
        _isRight: right,
        _isTop: top,
        _isBottom: bottom,
      })}
    >
      <img src={arrowSvg} alt="arrow button" />
    </div>
  );
};

export default Arrow;
