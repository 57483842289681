import App from '../App';
import Main from '../domains/main';
import Catalog from '../domains/catalog';
import Art from '../domains/art';

const routes = [
  {
    component: App,
    routes: [
      {
        path: '/',
        exact: true,
        component: Main,
      },
      {
        path: '/catalog',
        exact: true,
        component: Catalog,
      },
      {
        path: '/art',
        exact: true,
        component: Art,
      },
    ],
  },
];

export default routes;
