/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { ReactSVG } from 'react-svg';
import cn from 'classnames';
import css from './styles.module.scss';
import arrow from './icons/arrow.svg';

interface Prop {
  title: string;
  children: React.ReactNode;
}

const Tab: React.FC<Prop> = ({ title, children }) => {
  const [open,setOpen] = useState(false);
  return (
    <>
      <div className={cn(css.tab,{ isOpen: open})}>
        <div className={css.tab__body} onClick={()=>setOpen(!open)}>
          <div className={css.tab__title}>{title}</div>
          <ReactSVG className={css.tab__arrow} src={arrow} />
        </div>
        <div className={css.tab__content}>{children}</div>
      </div>
    </>
  );
};

export default Tab;
