import React from 'react';
import Footer from 'components/Footer';
import ScrollToTopButton from 'components/ScrollToTopButton';
import Cards from './Cards';
import Header from '../../components/Header';
import Slider from './Slider';

const Main: React.FC = () => {
  return (
    <>
      <Header black/>
      <Slider />
      <Cards />
      <Footer/>
      <ScrollToTopButton/>
    </>
  );
};

export default Main;
