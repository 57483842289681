import React from 'react';
import cn from 'classnames';
import css from './styles.module.scss';

interface Prop extends React.ButtonHTMLAttributes<HTMLButtonElement>{
  text: string;
  color: 'primary' | 'secondary';
  height?: number;
  width?: number;
}

const Button: React.FC<Prop> = ({ text, color, height, width, disabled,...buttonAttributes }) => {
  return (
    <button
      className={cn(css.button, {
        _isOragne: color === 'primary',
        _isBlue: color === 'secondary',
      })}
      style={{ height: `${height}px`, width: `${width}px` }}
      type='button'
      {...buttonAttributes}
      disabled={disabled}
    >
      {text}
    </button>
  );
};

export default Button;
