/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import cn from 'classnames';
import css from './styles.module.scss';

interface Prop {
  type: string;
  classname?: string;
  placeholder: string;
  style?: any;
  error?: boolean;
  disabled?: boolean;
}

const Input: React.FC<Prop> = ({
  type,
  classname,
  placeholder,
  style,
  error,
  disabled,
}) => {
  return (
    <div
      className={cn(css.input, classname, {
        _isError: error,
        _isDisabled: disabled,
      })}
      style={{ ...style }}
    >
      <input type={type} placeholder={placeholder} disabled={disabled}/>
      {error && <div className={css.error}>Incorrect value</div>}
    </div>
  );
};

export default Input;
