/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import cn from 'classnames';
import css from './styles.module.scss';

interface Prop {
  black?: boolean;
  open: boolean;
  setOpen: (name: string) => void;
}

const Burger: React.FC<Prop> = ({ black, open, setOpen }) => {
  return (
    <div
      className={cn(css.burgerMenu, {
        _isOpen: open,
        _isBlack: black,
      })}
      onClick={open ? () => setOpen('') : () => setOpen('burger')}
    >
      <div className={css.line}>{}</div>
    </div>
  );
};

export default Burger;
