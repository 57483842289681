import React from 'react';
import cn from 'classnames';
import { ReactSVG } from 'react-svg';
import css from './styles.module.scss';
import searchSvg from './icons/search.svg';

interface Prop {
  black?: boolean | undefined;
  full?: boolean;
}

const Search: React.FC<Prop> = ({ black, full }) => {
  return (
    <div
      className={cn(css.body, {
        _isBlack: black,
        _isFull: full,
      })}
    >
      <input className={css.input} type="text" placeholder="Search" />
      <ReactSVG className={css.icon} src={searchSvg} />
    </div>
  );
};

export default Search;
