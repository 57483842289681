/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import css from './styles.module.scss';

interface Prop {
  text: string;
}

const Checkbox: React.FC<Prop> = ({ text }) => {
  return (
    <label className={css.checkbox__body}>
      <input type="checkbox" name="checkbox" />
      <div className={css.checkbox__text}>{text}</div>
    </label>
  );
};

export default Checkbox;
