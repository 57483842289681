import React from 'react';
import css from './styles.module.scss';

interface Prop {
  children: React.ReactNode;
}

const Modal: React.FC<Prop> = ({ children }) => {
  return (
    <div className={css.modal}>
      <div className="container">{children}</div>
    </div>
  );
};

export default Modal;
