/* eslint-disable no-unused-expressions */
import React, {
  useState,
} from 'react';
import Search from 'components/Search';
import Burger from 'components/Burger';
import cn from 'classnames';
import { ReactSVG } from 'react-svg';
import { NavLink } from 'react-router-dom';
import Modal from 'components/Modal';
import css from './styles.module.scss';
import logoSvg from './icons/logo.svg';
import profileSvg from './icons/profile.svg';
import walletSvg from './icons/wallet.svg';

interface Prop {
  black?: boolean;
}

const Header: React.FC<Prop> = ({ black }) => {
  const [openModal, setOpenModal] = useState('');

  return (
    <>
      <div
        className={cn(css.wrapper, {
          _isBlack: black,
        })}
      >
        <div className="container">
          <div className={css.body}>
            <NavLink
              to={{
                pathname: `/`,
              }}
              className={css.logo}
            >
              <ReactSVG src={logoSvg} />
            </NavLink>
            {!openModal && (
              <div
                className={css.search}
                onClick={() => {
                  setOpenModal('search');
                }}
              >
                <Search black={black} />
              </div>
            )}

            <div className={css.menu}>
              <NavLink
                to={{
                  pathname: `/catalog`,
                }}
              >
                Marketplace
              </NavLink>
              <NavLink
                to={{
                  pathname: `/`,
                }}
              >
                Stats
              </NavLink>
              <NavLink
                to={{
                  pathname: `/`,
                }}
              >
                Resources
              </NavLink>
            </div>

            {!openModal && (
              <div className={css.actions}>
                <NavLink
                  to={{
                    pathname: `/`,
                  }}
                  className={css.profile}
                >
                  <ReactSVG src={profileSvg} />
                </NavLink>
                <NavLink
                  to={{
                    pathname: `/`,
                  }}
                  className={css.wallet}
                >
                  <ReactSVG src={walletSvg} />
                </NavLink>
              </div>
            )}
            <Burger
              black={black}
              open={openModal !== ''}
              setOpen={setOpenModal}
            />
          </div>
        </div>
      </div>
      {openModal === 'burger' && (
        <Modal>
          <div className={css.openBurger}>
            <NavLink
              to={{
                pathname: `/catalog`,
              }}
            >
              Marketplace
            </NavLink>
            <NavLink
              to={{
                pathname: `/`,
              }}
            >
              Stats
            </NavLink>
            <NavLink
              to={{
                pathname: `/`,
              }}
            >
              Resources
            </NavLink>
          </div>
        </Modal>
      )}

      {openModal === 'search' && (
        <Modal>
          <div className={css.searchModal}>
            <Search black full />
          </div>
        </Modal>
      )}
    </>
  );
};

export default Header;
