/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-inline-styles/no-inline-styles */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/label-has-associated-control */
import Card from 'components/Card';

import Select from 'react-select';
import Footer from 'components/Footer';
import Header from 'components/Header';
import React, { useRef } from 'react';
import Tab from 'components/Tab';
import Checkbox from 'components/Checkbox';
import { ReactSVG } from 'react-svg';
import Loading from 'components/Loading';
import Input from 'components/Input';
import ScrollToTopButton from 'components/ScrollToTopButton';
import css from './styles.module.scss';
import card1 from '../../assets/img/arts/card1.png';
import card2 from '../../assets/img/arts/card2.png';
import card3 from '../../assets/img/arts/card3.png';
import card4 from '../../assets/img/arts/card4.png';
import card5 from '../../assets/img/arts/card5.png';
import card6 from '../../assets/img/arts/card6.png';
import card7 from '../../assets/img/arts/card7.png';
import card8 from '../../assets/img/arts/card8.png';
import card9 from '../../assets/img/arts/card9.png';
import card10 from '../../assets/img/arts/card10.png';
import card11 from '../../assets/img/arts/card11.png';
import card12 from '../../assets/img/arts/card12.png';
import btcSVG from './icons/btc.svg';
import ehtSVG from './icons/eht.svg';
import mhcSVG from './icons/mhc.svg';

const images = [
  card1,
  card2,
  card3,
  card4,
  card5,
  card6,
  card7,
  card8,
  card9,
  card10,
  card11,
  card12,
];

const options = [
  {
    value: 'MHC',
    label: (
      <div className={css.selectLabel}>
        <ReactSVG src={mhcSVG} />
        MHC
      </div>
    ),
  },
  {
    value: 'EHT',
    label: (
      <div className={css.selectLabel}>
        <ReactSVG src={ehtSVG} />
        EHT
      </div>
    ),
  },
  {
    value: 'BTC',
    label: (
      <div className={css.selectLabel}>
        <ReactSVG src={btcSVG} />
        BTC
      </div>
    ),
  },
];

const Catalog: React.FC = () => {
  const ref = useRef(null);

  return (
    <>
      <Header />
      <div className="container">
        <div className={css.catalog}>
          <div className={css.filters}>
            <Tab title="Status">
              <div className={css.checkbox__group}>
                <Checkbox text="Buy Now" />
                <Checkbox text="On Auction" />
                <Checkbox text="New" />
                <Checkbox text="Has Offers" />
              </div>
            </Tab>
            <Tab title="Price">
              <div className={css.priceSelect}>
                <Select
                  options={options}
                  placeholder="Выбрать..."
                  classNamePrefix="priceInput"
                />
              </div>
              <div className={css.priceBoundaries}>
                <Input
                  type="text"
                  placeholder="min"
                  style={{ width: '100px' }}
                />
                <span> to </span>
                <Input
                  type="text"
                  placeholder="max"
                  style={{ width: '100px' }}
                  error
                />
              </div>
            </Tab>
            <Tab title="Collections">
              <Input
                type="text"
                placeholder="min"
                style={{ width: '100px' }}
                disabled
              />
            </Tab>
            <Tab title="Blockchains">tab</Tab>
            <Tab title="Categories">tab</Tab>
            <Tab title="On Sale In">tab</Tab>
          </div>
          <div className={css.arts}>
            <div className={css.numberOfArts}>
              <div className={css.mobileFilterBtn}>
                <div className={css.filtersBtn}>
                  <div className={css.filtersBtnLine}>{}</div>
                </div>
                <span>Filter</span>
              </div>
              <span>13,245 items</span>
            </div>
            <div className={css.gridWrapper} ref={ref}>
              <div className={css.grid}>
                {images.map((item) => (
                  <Card img={item} key={item} />
                ))}
                {images.map((item) => (
                  <Card img={item} key={item} />
                ))}
                {images.map((item) => (
                  <Card img={item} key={item} />
                ))}
              </div>
              <Loading />
              <ScrollToTopButton element={ref} classname={css.scrollToTop} />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Catalog;
