import React from 'react';
import cn from 'classnames';
import Card from 'components/Card';
import Arrow from 'components/Arrow';
import css from './styles.module.scss';
import card1 from '../../../assets/img/arts/card1.png';
import card2 from '../../../assets/img/arts/card2.png';
import card3 from '../../../assets/img/arts/card3.png';
import card4 from '../../../assets/img/arts/card4.png';
import card5 from '../../../assets/img/arts/card5.png';
import card6 from '../../../assets/img/arts/card6.png';
import card7 from '../../../assets/img/arts/card7.png';
import card8 from '../../../assets/img/arts/card8.png';
import card9 from '../../../assets/img/arts/card9.png';
import card10 from '../../../assets/img/arts/card10.png';
import card11 from '../../../assets/img/arts/card11.png';
import card12 from '../../../assets/img/arts/card12.png';

const images = [
  card1,
  card2,
  card3,
  card4,
  card5,
  card6,
  card7,
  card8,
  card9,
  card10,
  card11,
  card12,
];

const Cards: React.FC = () => {
  return (
    <div className={cn('container', css.catalog)}>
      <h2 className={css.catalog__title}>Exclusive MetaNFT drops</h2>
      <div className={css.catalog__items}>
        {images.map((img) => (
          <Card img={img} key={img}/>
        ))}
      </div>
      <div className={css.pages}>
        <Arrow left />
          <div className={cn(css.point, {
            _isActive: true
          })} />
          <div className={css.point} />
          <div className={css.point} />
          <div className={css.point} />
        <Arrow right />
      </div>
    </div>
  );
};

export default Cards;
