import React from 'react';
import { ReactSVG } from 'react-svg';
import css from './styles.module.scss';
import loadSVG from './icons/load.svg';

const Loading: React.FC = () => {
  return (
    <div className={css.loading}>
      <ReactSVG src={loadSVG} />
    </div>
  );
};

export default Loading;
