import React from 'react';
import { NavLink } from 'react-router-dom';
import css from './styles.module.scss';

const Footer: React.FC = () => {
  return (
    <div className={css.footer}>
      <div className="container">
        <div className={css.footer__links}>
          <NavLink
            to={{
              pathname: `/`,
            }}
          >
            How does it work?
          </NavLink>
          <NavLink
            to={{
              pathname: `/`,
            }}
          >
            Terms of Use
          </NavLink>
          <NavLink
            to={{
              pathname: `/`,
            }}
          >
            Privacy Policy
          </NavLink>
          <NavLink
            to={{
              pathname: `/`,
            }}
          >
            Contact Us
          </NavLink>
        </div>
        <div className={css.copyrate}>© 2018 - 2021 MetaHash Network</div>
      </div>
    </div>
  );
};

export default Footer;
