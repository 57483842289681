/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { ReactSVG } from 'react-svg';
import css from './styles.module.scss';
import favoriteSvg from './icons/favorite.svg';

interface Prop {
  img: string;
}

const Card: React.FC<Prop> = ({ img }) => {
  return (
    <div className={css.card}>
      <div className={css.card__art}>
        <img src={img} alt="card" />
        <div className={css.card__likes}>
          <ReactSVG src={favoriteSvg} />
          <span>12</span>
        </div>
      </div>
      <div className={css.card__artName}>Art name</div>
      <div className={css.card__collectionName}>Collection name</div>
      <div className={css.card__owner}>
        <div className={css.card__ownerName}>CurrentOwner</div>
        <div className={css.card__ownerValue}>24,000 MHC</div>
      </div>
    </div>
  );
};

export default Card;
